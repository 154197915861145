import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [formDt, setFormDt] = useState({ email: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState('')

  const doPostContact = () => {
    if(formDt.email.length < 5 || formDt.length < 5) return setFeedback('Preencha todos os campos');
    setFeedback('aguarde...')
    setLoading(true);
    var baseUrl = window.location.origin.indexOf('localhost') > -1 ? 'http://localhost:44313' : window.location.origin;
    fetch(baseUrl + '/site/sendContact', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formDt)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      setLoading(false);
      setFeedback(data.ok == true ? "Obrigado! Nosso time comercial entrará em contato em breve." : "Ops, ocoreu um erro.. Tente novamente, por favor.");
      //ChromeSamples.log('Created Gist:', data.html_url);
    });
  }

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section id="formContato"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-action" style={{ margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div className="cta-slogan">
              <h3 className="m-0" style={{ color: '#fff', marginBottom: 20 }}>
                Fale com nosso time comercial
              </h3>
            </div>
            <Input id="mensagem" type="textarea" label="Mensagem" labelHidden placeholder="Descreva a solução que sua empresa precisa"
              onChange={(e) => setFormDt({ ...formDt, message: e.target.value })} maxLength={800}
              style={{ marginBottom: 25 }}>
            </Input>
            <Input id="email" type="email" label="Email" labelHidden hasIcon="right"
              onChange={(e) => setFormDt({ ...formDt, email: e.target.value })} style={{ position: 'relative' }}
              placeholder="Seu Email">
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#2F67FC" />
              </svg>
              <div style={{ position: 'absolute', width: 48, height: 48, right: 0, bottom: 0, cursor: 'pointer', pointerEvents: 'auto' }}
                onClick={() => doPostContact()}></div>
            </Input>
            <div style={{ position: 'absolute', bottom: 30, color: '#fff', textAlign: 'center', width: 'min(90vw,520px)', fontSize: 16 }}>{feedback}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Nossas soluções',
    paragraph: 'Conheça alguns dos componentes utilizados em nossos sistemas de pagamentos, vendas e conciliação financeira'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} id="solutions">
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Gateway de pagamento multi adquirente
                  </div>
                <h3 className="mt-0 mb-12">
                  Cybercash Gateway
                  </h3>
                <p className="m-0">
                  Todas as adquirentes em um só Endpoint sempre disponível. Nossa API Rest de pagamentos eletrônicos proporciona versatilidade e economia para nossos clientes.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/cybercashGateway.png')}
                  alt="Cybercash Gateway"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Navegador da Web com TEF/POS/NFe
                  </div>
                <h3 className="mt-0 mb-12">
                  WebPOS Browser
                  </h3>
                <p className="m-0">
                  Um navegador feito sob medida para operações de vendas. WebPOS tem conexão com máquina de cartão de crédito, impressora térmica, balança e leitor de código de barras, além de aplicativos para aumento de produtividade do time de vendas.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/webPosBrowser.png')}
                  alt="WebPOS Browser"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Conta Digital
                  </div>
                <h3 className="mt-0 mb-12">
                  CyberBank
                  </h3>
                <p className="m-0">
                  Conta digital white-label com a identidade da sua empresa. Todos os serviços financeiros oferecidos com segurança em um ambiente controlado para seus colaboradores ou clientes.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/cyberBank.png')}
                  alt="CyberBank"
                  //style={{maxHeight: 360}}
                  width={398}
                  height={398} />
              </div>
            </div>



            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Checkout transparente
                  </div>
                <h3 className="mt-0 mb-12">
                  Cybercash Checkout
                  </h3>
                <p className="m-0">
                  Solução SPA de checkout transparente e personalizável. Pode ser enviado como um link para o cliente e permite pagamento com as modalidades mais aceitas no mercado.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/checkoutCybercash.png')}
                  alt="Checkout Cybercash"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Frente de Caixa
                  </div>
                <h3 className="mt-0 mb-12">
                  SisPDV
                  </h3>
                <p className="m-0">
                  Solução multi plataforma para o varejo e franquias. O sistema offline-first permite que a operação de vendas continue mesmo sem acesso à internet. Cardápio digital e eCommerce integrados.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/sisPdv.png')}
                  alt="SisPDV"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Pagamento WiFi
                  </div>
                <h3 className="mt-0 mb-12">
                  QR-Tef
                  </h3>
                <p className="m-0">
                  Pareamento de pinpad de última geração via QRCode, permitindo a integração de pagamentos sem fio à plataformas de vendas.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/QrTef.png')}
                  alt="QRTef"
                  width={396}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Sistema sob medida
                  </div>
                <h3 className="mt-0 mb-12">
                  Custom Software
                  </h3>
                <p className="m-0">
                  Desenvolvimento de soluções de software sob medida, com foco em otimização e aumento de produtividade da operação do cliente.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <video muted loop autoPlay oncanplay="this.muted=true"
                  style={{ width: 528, display: 'flex', height: 520 }}>
                  <source src={require("./../../assets/customApp.mp4")} type="video/mp4" />
                </video>
                {/* <Image
                  src={require('./../../assets/customsoftware.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} /> */}
              </div>
            </div>



          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;